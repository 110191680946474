<template>

    <v-chip
        class="person-label"
        small
        label
        color="#c2e6ff"
        @click="produceGlobalEvent"
    >
        <v-icon v-if="id == '31111111-1111-1111-1111-444444444444'" class="integroIcon">$integroIcon</v-icon>
        <v-icon v-else color="#494F59">{{ icon }}</v-icon>
        <div>{{ Name.toLowerCase() }}</div>
    </v-chip>

</template>

<script>

export default {
    name: "EmployeeChip",
    props: {
        id: {
            type: String,
            default: null
        },
        name: {
            type: String,
            default: null
        },
        formatter: {
            type: Function,
            default: null
        },
        icon: {
            type: String,
            default: "fas fa-user"
        },
        isEmployee: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        Name () {
            if (this.isEmployee)
                return this.name;
                
            if (this.formatter)
                return this.formatter(this.name);

            return this.sanitizeName(this.name);
        }
    },
    methods: {
        produceGlobalEvent(e) {
            //если система
            if (this.id == '31111111-1111-1111-1111-444444444444')
                return;

            e.stopPropagation();
            if (this.id)
                this.$eventBus.$emit('show-employee-information', this.id);
        },
        sanitizeName(source)
        {
            if (source.indexOf('(') > -1)
            {
                let splitResult = source.split('(');

                if (splitResult.length <= 0)
                    return source;

                source = splitResult[0].trim();
            }

            let detailedName = source.split(' ');

            if (detailedName.length <= 0)
                return source;

            let resultName;
            detailedName.forEach(function(namePart, index) {
                if (index === 0)
                    resultName = namePart;
                else
                    resultName += namePart[0] ? ` ${namePart[0]}.` : '';
            });

            return resultName;
        }
    }
}
</script>